import {  Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { Router } from '@angular/router';
import { SearchService } from '../core/shared/search/search.service';
import { expandSearchInput } from '../shared/animations/slide';

/**
 * The search box in the header that expands on focus and collapses on focus out
 */
@Component({
  selector: 'ds-search-navbar',
  templateUrl: './search-navbar.component.html',
  styleUrls: ['./search-navbar.component.scss'],
  animations: [expandSearchInput]
})
export class SearchNavbarComponent implements OnInit{

  // The search form
  searchForm;
  // Whether or not the search bar is expanded, boolean for html ngIf, string fo AngularAnimation state change
  searchExpanded = true;
  isExpanded = 'expanded';

  // Search input field
  @ViewChild('searchInput') searchField: ElementRef;

  constructor(private formBuilder: FormBuilder, private router: Router, private searchService: SearchService) {
    this.searchForm = this.formBuilder.group(({
      query: '',
    }));

  }

ngOnInit(): void {
      if (window.matchMedia('(max-width: 995px)').matches)  {
      console.log('kayn ha hwa');
      this.searchExpanded = false;
      this.searchField.nativeElement.blur();
    } else {
      this.searchExpanded = true;
      this.searchField.nativeElement.focus();
    }
}
  /**
   * Expands search bar by angular animation, see expandSearchInput
   */
  expand() {
    this.searchExpanded = true;
    this.isExpanded = 'expanded';
    this.editSearch();
  }

  /**
   * Collapses & blurs search bar by angular animation, see expandSearchInput
   */
  collapse() {
    this.searchField.nativeElement.focus();
    // this.searchExpanded = false;
    this.isExpanded = 'collapsed';
  }

  /**
   * Focuses on input search bar so search can be edited
   */
  editSearch(): void {
    this.searchField.nativeElement.focus();
  }



  /**
   * Submits the search (on enter or on search icon click)
   * @param data  Data for the searchForm, containing the search query
   */
  onSubmit(data: any) {
    // this.collapse();
    const queryParams = Object.assign({}, data);
    const linkToNavigateTo = [this.searchService.getSearchLink().replace('/', '')];
    this.searchForm.reset();

    this.router.navigate(linkToNavigateTo, {
      queryParams: queryParams,
      queryParamsHandling: 'merge'
    });
  }
}
