import { Component } from '@angular/core';
import { NavbarComponent as BaseComponent } from '../../../../app/navbar/navbar.component';
import { slideMobileNav } from '../../../../app/shared/animations/slide';

/**
 * Component representing the public navbar
 */
@Component({
  selector: 'ds-navbar',
  styleUrls: ['./navbar.component.scss'],
  templateUrl: './navbar.component.html',
  animations: [slideMobileNav]
})
export class NavbarComponent extends BaseComponent {
  public isCollapsed = true;
  

   /**
   * Submits the search (on enter or on search icon click)
   * @param data  Data for the searchForm, containing the search query
   */
   onSubmit() {
    // this.collapse();
   
    const linkToNavigateTo = [this.searchService.getSearchLink().replace('/', '')];
    // this.searchForm.reset();

    this.router.navigate(linkToNavigateTo, {
    
    });
  }
  
}
