<div *ngIf="buttonVisible$ | async">
  <a href="javascript:void(0);"
     role="button"
     (click)="onClick()"
     [attr.aria-label]="'nav.context-help-toggle' | translate"
     [title]="'nav.context-help-toggle' | translate"
  >

    <i class="fas fa-lg fa-fw fa-question-circle ds-context-help-toggle"></i>
  </a>
</div>
