<ul

  class="navbar-nav desconnect-class"
  [ngClass]="{ 'mr-auto': (isXsOrSm$ | async) }"
>
  <li
    *ngIf="
      !(isAuthenticated | async) && !(isXsOrSm$ | async) && (showAuth | async)
    "
    class="nav-item"
    (click)="$event.stopPropagation()"
  >
    <div
      ngbDropdown
      #loginDrop
      display="dynamic"
      placement="bottom-right"
      class="d-inline-block"
      @fadeInOut
    >
      <a
        href="javascript:void(0);"
        class="dropdownLogin px-1"
        [attr.aria-label]="'nav.login' | translate"
        (click)="$event.preventDefault()"
        [attr.data-test]="'login-menu' | dsBrowserOnly"
        ngbDropdownToggle
        >{{ "nav.login" | translate }}</a
      >
      <div
        class="loginDropdownMenu"
        [ngClass]="{ 'pl-3 pr-3': (loading | async) }"
        ngbDropdownMenu
        [attr.aria-label]="'nav.login' | translate"
      >
        <ds-log-in [isStandalonePage]="false"></ds-log-in>
      </div>
    </div>
  </li>
  <li
    *ngIf="!(isAuthenticated | async) && (isXsOrSm$ | async)"
    class="nav-item"
  >
    <a routerLink="/login" routerLinkActive="active" class="loginLink px-1">
      {{ "nav.login" | translate }}<span class="sr-only">(current)</span>
    </a>
  </li>

  <li
    *ngIf="
      (isAuthenticated | async) && !(isXsOrSm$ | async) && (showAuth | async)
    "
    class="nav-item"
  >
    <div
      ngbDropdown
      display="dynamic"
      placement="bottom-right"
      class="d-inline-block"
      @fadeInOut
    >
      <a
        href="javascript:void(0);"
        role="button"
        [attr.aria-label]="'nav.user-profile-menu-and-logout' | translate"
        (click)="$event.preventDefault()"
        [title]="'nav.user-profile-menu-and-logout' | translate"
        class="px-1"
        style="text-decoration: none !important;"
        [attr.data-test]="'user-menu' | dsBrowserOnly"
        ngbDropdownToggle
      >
        <!-- <i class="fas fa-user-circle fa-lg fa-fw"></i></a> -->
        <div style="padding: 20px;" class="d-flex flex-row align-items-center gap-2">
          <i
            style="
              padding: 16px;

              background: url(../../../assets/images/profil.png);
              border-radius: 16px;
              background-size: 35px;
              flex: none;
              order: 0;
              flex-grow: 0;
            "
            class=""
          ></i>
          <div style="padding-right: 34px" class="d-flex flex-column gap-4">
            <div
              style="
                font-family: 'Arial';
                font-style: normal;
                font-weight: bold;
                font-size: 13.2px;
                line-height: 20px;
                color: #d9dcdd;
              "
            >
              {{ (user | async)?.name.toUpperCase() }}
            </div>
            <div
              style="
              font-family: 'Arial';
              font-style: normal;
                font-weight: 300;
                font-size: 12px;
                line-height: 18px;
                color: #ffff;
              "
            >
              {{ (user | async)?.email }}
            </div>
          </div>
        </div>
      </a>

      <div
        class="logoutDropdownMenu"
        ngbDropdownMenu
        [attr.aria-label]="'nav.user-profile-menu-and-logout' | translate"
      >
        <ds-user-menu></ds-user-menu>
      </div>
    </div>
  </li>
  <li *ngIf="(isAuthenticated | async) && (isXsOrSm$ | async)" class="nav-item">
    <a
      id="logoutLink"
      role="button"
      [attr.aria-label]="'nav.logout' | translate"
      [title]="'nav.logout' | translate"
      routerLink="/logout"
      routerLinkActive="active"
      class="px-1"
    >
      <i class="fas fa-sign-out-alt fa-lg fa-fw"></i>
      <span class="sr-only">(current)</span>
    </a>
  </li>
</ul>
