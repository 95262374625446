<nav class="navbar navbar-expand-lg navbar-light navbar-default  bg-light fixed-top" 
role="navigation"
[attr.aria-label]="'nav.main.description' | translate"
[ngClass]="{ open: !(menuCollapsed | async) }"
  [@slideMobileNav]="
    !(windowService.isXsOrSm() | async)
      ? 'default'
      : (menuCollapsed | async)
      ? 'collapsed'
      : 'expanded'
  ">
  <a class="pl-5 pt-2" routerLink="/home">
    <img src="assets/images/new_logo.svg" [attr.alt]="'menu.header.image.logo' | translate" />
  </a>
  <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarColor03"
    aria-controls="navbarColor03" aria-expanded="false" aria-label="Toggle navigation"
    (click)="isCollapsed = !isCollapsed">
    <span class="navbar-toggler-icon"></span>
  </button>

  <div class="collapse navbar-collapse" id="navbarColor03" [ngbCollapse]="isCollapsed">

    <ul class="navbar-nav">
    
      <li class="nav-item big-serche">
         <ds-search-navbar ></ds-search-navbar>
      </li>

      <li class="nav-item ">
        <ds-lang-switch ></ds-lang-switch>
      </li>
      <li  class="nav-item small-serche">
        <a class="submit-icon" [routerLink]="" (click)="onSubmit()">
         {{'nav.search' | translate}} <em style="color: #014F9C !important;" class="fas fa-search fa-lg fa-fw"></em>
        </a>
      <!-- <button (click)="onSubmit()"> my serche</button> -->
    </li>
      <li class="nav-item" *ngIf="(isXsOrSm$ | async) && (isAuthenticated$ | async)">
        <ds-user-menu [inExpandableNavbar]="true"></ds-user-menu>
      </li>
      
        <ng-container *ngFor="let section of (sections | async)">
          <ng-container
            *ngComponentOutlet="(sectionMap$ | async).get(section.id)?.component; injector: (sectionMap$ | async).get(section.id)?.injector;"></ng-container>
        </ng-container>
      
      <li class="nav-item">
        <ds-context-help-toggle ></ds-context-help-toggle>
      </li>
      <li class="nav-item">
        <ds-themed-auth-nav-menu ></ds-themed-auth-nav-menu>
      </li>
  
      <li class="nav-item">
        <ds-impersonate-navbar ></ds-impersonate-navbar>
      </li>
    </ul>

  </div>
</nav>